@import "~font-awesome/css/font-awesome.css";
@import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

body {
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Hide scrollbars for all elements */
* {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

*::-webkit-scrollbar {
    width: 0px; /* Safari and Chrome */
    height: 0px; /* Safari and Chrome */
}

.custom-backdrop {
    background: black;
    opacity: 0.8 !important;
}

.ant-collapse-content-box {
    padding: 0 !important;
}

.ant-dropdown-menu {
    min-width: 140px;
}

.custom-card-background {
    background: linear-gradient(121.51deg, #ffffff 4.2%, #c6fff7 93.09%);
}

.custom-info-background {
    background: linear-gradient(119.86deg, #ffffff 1.53%, #ffffff 42.98%, #c6fff7 117.54%);
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0e5ebc !important;
    border-color: #0e5ebc !important;
}

.ant-radio-checked .ant-radio-inner {
    background-color: #0e5ebc !important;
    border-color: #0e5ebc !important;
}

.ant-input::placeholder {
    color: #808080;
}

/* Enabling visibility of scroll for Tables */
.table-container {
    position: relative;
    overflow: hidden;
}

.table-container .react-bootstrap-table {
    position: relative;
    min-width: 100%;
    margin-bottom: 0px !important;
}

.table-container .react-bootstrap-table-container .react-bootstrap-table {
    display: block;
    overflow-x: auto; /* Enable horizontal scroll */
    overflow-y: auto; /* Enable vertical scroll  */
    scrollbar-width: auto;
    -ms-overflow-style: auto;
}

.table-container .react-bootstrap-table-container .react-bootstrap-table::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.table-container .react-bootstrap-table-container .react-bootstrap-table::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border-radius: 16px;
}

/* Enabling visibility of vertical scroll in timeline cards */
.timeline-card-content {
    position: relative;
    overflow-y: auto;
    scrollbar-width: auto;
    -ms-overflow-style: auto;
}

.timeline-card-content::-webkit-scrollbar {
    width: 4px;
}

.timeline-card-content::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border-radius: 16px;
}

.timeline-shadow-box {
    position: absolute;
    height: 100%;
    z-index: 2;
    background: linear-gradient(90deg, rgba(247, 245, 245, 0.8) 0%, rgba(255, 255, 255, 0.1) 78.57%);
    width: 19px;
}


.timeline-container {
    position: relative;
    overflow-x: auto;
    scrollbar-width: auto;
    -ms-overflow-style: auto;
}

.timeline-container::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.timeline-container::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border-radius: 16px;
}

/* Custom tooltip background color */
.custom-tooltip .tooltip-inner {
    background-color: #062E55; /* Change to your desired background color */
    color: #ffffff; /* Change text color if needed */
    border-radius: 4px; /* Optional: Adjust border radius */
    padding: 8px; /* Optional: Adjust padding */
}

/* Custom tooltip arrow color */
.custom-tooltip .tooltip-arrow::before {
    border-top-color: #062E55; /* Change to match the background color */
}

/* scroll for line graph */
.scrollable-chart-container {
    scrollbar-width: auto;
}

.scrollable-chart-container::-webkit-scrollbar {
    height: 6px;
}

.scrollable-chart-container::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border-radius: 16px;
}

/* styling for select component */
.custom-select .ant-select-selection-item {
    font-weight: 600;
    white-space: pre-line;
    word-break: break-word;
    display: block;
    overflow-wrap: break-word;
    line-height: 1rem !important;
    padding: 6px 0px !important;
}

.ant-select-selection-placeholder {
    color: #808080 !important;
    font-weight: 500;
}

.ant-select-focused .ant-select-selector {
    outline: 2px solid #0e5ebc !important;
}

.note {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}
